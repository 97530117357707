import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

class Resources extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <SEO title={"Resources"} titleDesc={"-"} titleKeywords={"-"} fbDes={"-"} twitDesc={"-"} seoLink={"/"} />

        {/* top section */}
        <section role="top--section" className="pb-5">
          <article className="container">
            <Row className="align-items-center mt-5">
              <Col lg={12} className="text-center">
                <h1 className="font-weight-bold">Get Started with Fuse Classroom</h1>
                <p className="h5 font-weight-normal mt-3">
                  Learn how you run your institution and also bring the transformation in how learning itself is
                  re-imagined in the new AI assisted age.
                </p>
              </Col>
            </Row>

            <Row className="justify-content-center mt-5">
              <Col md={3} className="my-3">
                <a href="https://insights.fuseclassroom.com/" className="resources-block">
                  <img src="/images/insight.svg" alt="Insights" />
                  <div className="h4">Insights</div>
                  <div className="mb-0 h6">Get the freshest stories from the Fuse Classroom.</div>
                </a>
              </Col>

              <Col md={3} className="my-3">
                <Link to="/resources/videos" className="resources-block">
                  <img src="/images/video.svg" alt="Videos" />
                  <div className="h4">Walkthrough Videos</div>
                  <div className="mb-0 h6">Explore our Fuse Classroom Platform video.</div>
                </Link>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}
      </Layout>
    )
  }
}

export default Resources
